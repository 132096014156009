<template>
  <div class="steps-wrap">
    <div class="steps-wrap-items">
      <div
        class="steps-wrap-dot"
        :class="{
          'steps-wrap-dot-active': current >= item,
          'dot-l': index === 0
        }"
        :style="{
          left: portion * index + '%'
        }"
        v-for="(item, index) in steps"
        :key="index"
        :data-value="item + 'x'"
        @click="() => onSlide(index, item)"
      >
      </div>

      <div
        class="steps-wrap-lined"
        :style="{ width: activeSty }"
      />
      <div class="steps-wrap-dot-diy" :style="{left: activeSty}" />
    </div>
    <p class="steps-wrap-notice">
      {{ $t('features.stepNotice') }}
    </p>
  </div>
</template>
<script>
let portion = 0
let spy = 0
export default {
  props: {
    steps: {
      type: Array,
      default: () => [5, 10, 20, 50, 100]
    },
    lever: {
      type: Number,
      default: 1
    }
  },
  computed: {
    len () {
      return this.steps.length - 1
    },
    portion () {
      return (100 / this.len).toFixed(2)
    },
    current: {
      get () {
        return this.lever
      },
      set (val) {
        this.$emit('update:lever', val)
      }
    },
    activeSty () {
      // 获取分段百分比
      const totalPortion = 100 / this.len
      const fIdx = this.steps.findIndex(f => f === this.current)
      const n = this.steps[fIdx + 1]
      const current = this.current - this.steps[0]

      if (fIdx > -1 && n) {
        // 计算段与段时间的差值，获取对应的间隔值百分比
        const interval = n - this.steps[fIdx]
        portion = totalPortion / interval
      }
      if (fIdx > 0) {
        // 累积激活段
        spy = (totalPortion * fIdx) - portion * current
      }
      const pro = portion * current + spy
      return `${pro}%`
    }
  },
  methods: {
    onSlide (index, lever) {
      this.current = lever
    }
  }
}
</script>
<style lang="less">
.steps-wrap {
  padding-right: 42px;
  width: 100%;
  box-sizing: border-box;
  &-items {
    position: relative;
    height: 60px;
    width: 100%;
    box-sizing: border-box;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 5px;
      width: 100%;
      height: 4px;
      background-color: var(--background-color-2);
      padding-right: 12px;
      box-sizing: border-box;
    }
  }
  &-lined {
    content: "";
    position: absolute;
    top: 5px;
    height: 4px;
    background-color: var(--primary-color);
    z-index: 1;
  }
  &-dot-diy {
    position: absolute;
    top: -2px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: var(--primary-color);
    z-index: 2;
  }
  &-dot {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: var(--divider-color);
    z-index: 2;
    position: absolute;
    &::after {
      content: attr(data-value);
      position: absolute;
      left: -12px;
      top: 26px;
      color: var(--main-text-2);
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      z-index: -1;
    }
  }
  &-dot-active {
    background-color: var(--primary-color);
  }
  .dot-l {
    &::after {
      left: 0;
    }
  }
  &-notice {
    margin: 18px 0 68px;
    color: var(--main-text-3);
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
  }
}
</style>
