<template>
  <div class="feature-index-search">
    <van-popup
      class="feature-index-search-popup"
      v-model="show"
      round
      close-icon="close"
      position="bottom"
      :style="{ height: 'calc(100% - 60px)', overflow: 'hidden' }"
      :get-container="'.feature-index-search'"
    >
      <div class="title ub ub-btw">
        <span>
          {{ $t("features.usd@mfeatures") }}
        </span>
        <img
          src="@/assets/images/system/cross.svg"
          alt="close"
          title="close"
          @click="show = false"
        />
      </div>
      <div class="feature-pup-market-wrapper">
          <div class="padding-left15 padding-right15 padding-top15 pop-search-box-wrap" @click="goSearch">
            <van-search
              shape="round"
              :disabled="true"
              :placeholder="$t('nav.search')"
            />
            <div class="search-mask-box" @click="goSearch"></div>
          </div>
          <div class="list-box-wrap ">
            <van-tabs v-model="tabActive" background="var(--background-color-4)" title-active-color="var(--main-text-1)">
              <van-tab
                v-for="tab in tabs"
                :key="tab.value"
                :title="$t(`market.${tab.label}`)"
              >
                <div class="component-wrapper padding15 proHeight">
                  <component :is="tab.components" :ref="tab.components"></component>
                </div>
              </van-tab>
            </van-tabs>
          </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import All from '@/components/market/all/index.vue'
import Favorites from '@/components/market/favorites/index.vue'

import { mapMutations } from 'vuex'

export default {
  components: {
    All,
    Favorites
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (bool) {
        this.$emit('update:visible', bool)
      }
    }
  },
  data () {
    return {
      bg: 'var(--background-color-2)',
      tabActive: 1,
      empty: false,
      tabs: [
        { label: 'favorites', value: 1, components: 'Favorites' },
        { label: 'all', value: 2, components: 'All' }
      ]
    }
  },
  watch: {
    tabActive () {
      this.$nextTick(() => {
        this.clearChaildEvent()
        this.emitChaildEvent()
      })
    },
    show (n, o) {
      if (n) {
        this.emitChaildEvent()
      }
      if (!n) {
        this.clear()
      }
    },
    $route (n, o) {
      if (n.params) {
        this.updateFutureCoin(n.params.symbol)
      }
      console.log(n, o)
    }
  },
  methods: {
    clear () {
      if (this.$refs.All) {
        this.$refs.All[0].cleaeEvent()
      }
      if (this.$refs.Favorites) {
        this.$refs.Favorites[0].cleaeEvent()
      }
    },
    goSearch () {
      console.log('goSearch=======')
      this.$router.push({
        path: '/search'
      })
    },
    clearChaildEvent () {
      const handle = {
        Favorites: () => {
          this.$refs.All[0].cleaeEvent()
        },
        All: () => {
          this.$refs.Favorites[0].cleaeEvent()
        }
      }
      const tabActive = this.tabs[this.tabActive].components
      handle[tabActive]()
    },
    emitChaildEvent () {
      console.log(this.$refs, 'this.$refs.-------')
      const handle = {
        Favorites: () => {
          this.$refs.Favorites[0].emitEvent()
        },
        All: () => {
          this.$refs.All[0].emitEvent()
        }
      }
      const tabActive = this.tabs[this.tabActive].components
      this.$nextTick(() => handle[tabActive]())
    },
    ...mapMutations(['updateFutureCoin'])
  }
}
</script>
<style lang="less">
@import "~@/assets/styles/mixins.less";
.feature-index-search {
  &-popup {
    background-color: var(--bg-1) !important;
    box-sizing: border-box;
    // overflow: scroll;
    .market-wrapper {
      height: calc(100% - 48px);
      .proHeight {
        height: 76vh;
        box-sizing: border-box;
      }
    }
    .title {
      padding: 0 30px;
      margin: 48px 0 0px;
      color: var(--main-text-1);
      font-family: Roboto;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
    img {
      width: 48px;
      height: 48px;
    }
    }
    .van-search {
      height: 64px;
      padding: 0;
      border-radius: 30px;
      box-sizing: border-box;
      overflow: hidden;
      .van-cell {
        background-color: #eaecf1;
      }
    }
  }
  .feature-pup-market-wrapper {
    height: 100%;
    background-color: #fff;
    .pop-search-box-wrap{
      position: relative;
      .search-mask-box{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0);
      }
    }
    .list-box-wrap{
      overflow: scroll;
      height: calc(100% - 120px);
      padding-top: 30px;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      background-color: #fff;
    }
    .component-wrapper {
      border-top: 1px solid var(--divider-color);
      position: relative;
      // height: calc(100vh - 259px - 148px) 116px 98px 104px 36
    }
    .devHeight {
      height: calc(100vh - 259px)
    }
    .proHeight {
      height: calc(100vh - 259px)
    }
    .empty {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .van-search {
      padding: 0;
      margin: 0;
      border-radius: 30px;
      .van-cell {
        background-color: #F5F5F5;
      }
      .van-search__content {
        background-color: #F5F5F5;
      }
    }
    .van-tab {
      flex: none;
      margin-right: 48px;
      padding-bottom: 1.06667vw;
      font-weight: 500;
      font-size: 28px;
      color: var(--main-text-2);
      &--active {
        .border-1px(var(--primary-color), solid, 12px);
        color: var(--main-text-1);
        &::after {
          top: -1px;
        }
      }
    }
    .van-tabs {
      &__wrap {
        margin: 0 30px;
        height: 104px;
        background-color: var(--input-bg);
      }
      &__nav {
        background-color: var(--input-bg);
      }
      &__line {
        background-color: transparent;
      }
      &__nav--line {
        padding: 0;
      }
    }
  }
}
</style>
