var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feature-index-handle-wrapper border-top-radius30"},[_c('HandleTitle',{ref:"title"}),_c('div',{staticClass:"feature-index-handle-content ub ub-btw"},[_c('div',{staticClass:"feature-index-handle-operate"},[_c('div',{staticClass:"feature-index-handle-operate-title ub ub-cen"},[_c('div',{staticClass:"btn skew-l feature-index-handle-operate-button",class:{'skew-l-a': _vm.isBuy},style:({
          color: _vm.isBuy ? _vm.activeColor : _vm.unColor
        }),on:{"click":function($event){_vm.isBuy = true}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("features.open"))+" ")])]),_c('div',{staticClass:"btn skew-r feature-index-handle-operate-button",class:{'skew-r-a': !_vm.isBuy},style:({
          color: !_vm.isBuy ? _vm.activeColor : _vm.unColor
        }),on:{"click":function($event){_vm.isBuy = false}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("features.close"))+" ")])])]),_c('div',{staticClass:"feature-index-handle-operate-withdraw ub ub-btw"},[_c('span',[_vm._v(_vm._s(_vm.$t("features.avbl")))]),_c('div',{staticClass:"ub ub-btw"},[_c('span',{staticClass:"usdt"},[_vm._v(_vm._s(_vm.availableAmount)+" USDT")]),_c('img',{attrs:{"src":require("@/assets/images/feature/deposit.svg")}})])]),_c('div',{staticClass:"feature-index-handle-operate-lever"},[_c('div',{staticClass:"feature-index-handle-operate-amount ub ub-btw",on:{"click":_vm.onShowKeyboard}},[_c('span',{class:{ active: _vm.value }},[_vm._v(_vm._s(_vm.value ? _vm.value : "Amount"))]),_c('span',[_vm._v("USDT")])]),_c('div',{staticClass:"feature-index-handle-operate-percentage ub"},_vm._l((_vm.percentage),function(item,idx){return _c('div',{key:item.value,staticClass:"ub-f1 percentage-item",class:{
            m16: idx !== 0 && idx !== _vm.percentage.length - 1,
            'percentage-item-active': _vm.percentageActive >= idx,
            'text-active': _vm.percentageActive === idx
          },attrs:{"data-value":item.label},on:{"click":() => _vm.onPlusAmount(item, idx)}})}),0)]),_c('div',{staticClass:"feature-index-handle-operate-symbol ub"},[_c('div',{staticClass:"ub ub-btw"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t("features.min")))]),_c('div',{staticClass:"ub ub-btw"},[_c('span',{staticClass:"usdt"},[_vm._v(_vm._s(_vm.orderMin)+" USDT ")])])]),_c('div',[_c('span',[_vm._v("≈ "+_vm._s(_vm.totoal)+" "+_vm._s(_vm.coins))])])]),_c('div',{staticClass:"feature-index-handle-operate-operate ub ub-btw"},[_c('van-button',{staticClass:"button",attrs:{"type":"primary","color":_vm.buyColor,"disabled":!_vm.value},on:{"click":_vm.onBuy}},[_c('p',[_vm._v(_vm._s(_vm.isBuy ? _vm.$t("features.openLong") : _vm.$t("history.closeLong")))])]),_c('van-button',{staticClass:"button",attrs:{"type":"primary","color":_vm.sellColor,"disabled":!_vm.value},on:{"click":_vm.onSell}},[_c('p',[_vm._v(_vm._s(_vm.isBuy ? _vm.$t("features.openShort") : _vm.$t("history.closeShort")))])])],1),_c('div',{staticClass:"margin-top24 ub ub-btw font14 font-weight500"},[_vm._v(" Positions "),_c('div',{staticClass:"ub ub-cen"},[_c('img',{attrs:{"src":require("@/assets/images/walltes/list.svg"),"alt":"","srcset":""},on:{"click":_vm.goHistory}})])]),_c('PositionDom',{ref:"PositionDom"}),_c('van-number-keyboard',{attrs:{"show":_vm.showKeyboard,"maxlength":16},on:{"blur":function($event){_vm.showKeyboard = false}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('SearchPopUp',{attrs:{"visible":_vm.marketVibile},on:{"update:visible":function($event){_vm.marketVibile=$event}}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }