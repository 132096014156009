<template>
  <div class="feature-index-handle-wrapper border-top-radius30">
    <HandleTitle ref="title" />
    <div class="feature-index-handle-content ub ub-btw">
      <div class="feature-index-handle-operate">
        <div class="feature-index-handle-operate-title ub ub-cen">
          <div class="btn skew-l feature-index-handle-operate-button" @click="isBuy = true" :style="{
            color: isBuy ? activeColor : unColor
          }"
          :class="{'skew-l-a': isBuy}"
          >
            <span>
              {{ $t("features.open") }}
            </span>
          </div>
          <div class="btn skew-r feature-index-handle-operate-button" @click="isBuy = false" :style="{
            color: !isBuy ? activeColor : unColor
          }"
          :class="{'skew-r-a': !isBuy}">
            <span>
              {{ $t("features.close") }}
            </span>
          </div>
        </div>
        <div class="feature-index-handle-operate-withdraw ub ub-btw">
          <span>{{ $t("features.avbl") }}</span>
          <div class="ub ub-btw">
            <span class="usdt">{{ availableAmount }} USDT</span>
            <img src="@/assets/images/feature/deposit.svg" />
          </div>
        </div>
        <div class="feature-index-handle-operate-lever">
          <div class="feature-index-handle-operate-amount ub ub-btw" @click="onShowKeyboard">
            <span :class="{ active: value }">{{
              value ? value : "Amount"
            }}</span>
            <span>USDT</span>
          </div>
          <div class="feature-index-handle-operate-percentage ub">
            <div
            class="ub-f1 percentage-item"
            v-for="(item, idx) in percentage"
            :key="item.value"
            :data-value="item.label"
            :class="{
              m16: idx !== 0 && idx !== percentage.length - 1,
              'percentage-item-active': percentageActive >= idx,
              'text-active': percentageActive === idx
            }"
            @click="() => onPlusAmount(item, idx)"
            >
              <!-- {{ item.label }} -->
            </div>
          </div>
        </div>
        <div class="feature-index-handle-operate-symbol ub">
          <div class="ub ub-btw">
            <span class="label">{{ $t("features.min") }}</span>
            <div class="ub ub-btw">
              <span class="usdt">{{ orderMin }} USDT </span>
            </div>
          </div>
          <div>
            <span>≈ {{ totoal }} {{ coins }}</span>
          </div>
          <!-- <SlippageTolerance /> -->
        </div>
        <!-- 开仓 -->
        <div class="feature-index-handle-operate-operate ub ub-btw">
          <van-button type="primary" class="button" :color="buyColor" @click="onBuy" :disabled="!value">
            <p>{{ isBuy ? $t("features.openLong") : $t("history.closeLong") }}</p>
            <!-- <span>≈ {{ totoal }} {{ coins }}</span> -->
          </van-button>
          <van-button type="primary" class="button" :color="sellColor" @click="onSell" :disabled="!value">
            <p>{{ isBuy ? $t("features.openShort") : $t("history.closeShort") }}</p>
            <!-- <span>≈ {{ totoal }} {{ coins }}</span> -->
          </van-button>
        </div>
        <div class="margin-top24 ub ub-btw font14 font-weight500">
          Positions
          <div class="ub ub-cen">
            <img @click="goHistory" src="@/assets/images/walltes/list.svg" alt="" srcset="">
          </div>
        </div>
        <PositionDom ref="PositionDom"></PositionDom>
        <van-number-keyboard v-model="value" :show="showKeyboard" :maxlength="16" @blur="showKeyboard = false" />
        <SearchPopUp :visible.sync="marketVibile" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
// import SlippageTolerance from '@/components/feature/slippageTolerance'
import HandleTitle from '@/components/feature/title'
import SearchPopUp from '@/components/feature/searchPopUp'
import PositionDom from '@/components/walltes/position/index.vue'

import { tradeOrder } from '@/api/trade.js'
import { fundBalance } from '@/api/assetsCenter.js'

export default {
  components: { HandleTitle, SearchPopUp, PositionDom },
  props: {
    showMarket: {
      type: Boolean,
      default: false
    },
    price24: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      activeColor: '#fff',
      unColor: 'var(--main-text-2)',
      buyColor: 'var(--up-color)',
      sellColor: 'var(--down-color)',
      isBuy: true,
      value: '',
      showKeyboard: false,
      orderMin: 5.1, // 更改最小下单U，对应的国际化也需要更改
      percentageActive: null,
      percentage: [
        { label: '25%', value: 0.25 },
        { label: '50%', value: 0.5 },
        { label: '75%', value: 0.75 },
        { label: '100%', value: 1 }
      ]
    }
  },
  computed: {
    symbol () {
      return this.$route.params.symbol || 'BTCUSDT'
    },
    coins () {
      return this.symbol.replace('USDT', '')
    },
    marketVibile: {
      get () {
        return this.showMarket
      },
      set (bool) {
        this.$emit('update:showMarket', bool)
      }
    },
    TPrice () {
      return this.price24?.lastPrice || 0
    },
    totoal () {
      return +this.value === 0 ? (+this.TPrice).toFixed(6) : (this.value / this.TPrice).toFixed(6)
    },
    availableAmount () {
      return this.totalAmount?.available_amount || 0
    },
    ...mapGetters(['totalAmount'])
  },
  created () {
    this.getBalance()
  },
  methods: {
    goHistory () {
      this.$router.push({
        path: '/history/futures/orders'
      })
    },
    getBalance () {
      return fundBalance().then(res => {
        if (res) {
          this.updateTotalAssets(res.data)
        }
        return res
      })
    },
    onPlusAmount ({ value }, currentIdx) {
      this.value = (this.availableAmount * value).toFixed(2)
      this.percentageActive = currentIdx
    },
    async onSubmit (params) {
      return Promise.all([tradeOrder(params), this.getBalance()]).then(ress => {
        this.$toast(this.$t('nav.successText'))
        this.value = ''
      })
    },
    onBuy () {
      // 开多：买入开多（side 填写 BUY； posSide 填写 LONG ）
      // 平多：卖出平多（side 填写 SELL；posSide 填写 LONG ）
      const side = this.isBuy ? 'BUY' : 'SELL'
      const price = this.price24.lastPrice
      const currentLever = this.$refs.title.lever
      const quantity = (this.value * currentLever) / price
      if (this.openConditions(price, quantity, currentLever, side) && this.isBuy) {
        // 看多
        return false
      }
      if (!this.isBuy && this.closeConditions(quantity)) {
        // 平多
        return false
      }
      this.onSubmit({
        side,
        pos_side: 'LONG',
        price_match: `OPPONENT_${currentLever}`,
        symbol: this.symbol,
        price,
        type: 'MARKET',
        quantity
      })
    },
    onSell () {
      // 开空：卖出开空（side 填写 SELL； posSide 填写 SHORT
      // 平空：买入平空（side 填写 BUY； posSide 填写 SHORT ）
      const side = this.isBuy ? 'SELL' : 'BUY'
      const price = this.price24.lastPrice
      const currentLever = this.$refs.title.lever
      const quantity = (this.value * currentLever) / price
      if (this.isBuy && this.openConditions(price, quantity, currentLever, side)) {
        // 开空
        return false
      }
      if (!this.isBuy && this.closeConditions(quantity)) {
        // 平空
        return false
      }
      this.onSubmit({
        side,
        pos_side: 'SHORT',
        price_match: `OPPONENT_${currentLever}`,
        symbol: this.symbol,
        price,
        type: 'MARKET',
        quantity
      })
    },
    openConditions (price, quantity, lever) {
      if (+this.value === 0 || this.availableAmount === 0) {
        this.$toast(this.$t('features.emptyNumber'))
        return true
      }
      // 开仓不能小于 orderMin
      if (this.value < this.orderMin) {
        this.$toast(this.$t('features.orderMinText'))
        return true
      }
      // 开仓金额不能大于钱包约
      if (this.value > this.availableAmount) {
        this.$toast(this.$t('features.insuffcientMargin'))
        this.value = ''
        return true
      }
      // 不能超出钱包能购买的数量
      const maxCoins = this.availableAmount * lever / price
      if (maxCoins < quantity) {
        this.$toast(this.$t('features.purchaseError'))
        return true
      }
      return false
    },
    closeConditions (quantity) {
      const side = !this.isBuy ? 'BUY' : 'SELL'
      const CoinsQuantityTotal = this.getCoinsQuantity(side)
      if (+this.value === 0 || this.availableAmount === 0) {
        this.$toast(this.$t('features.emptyNumber'))
        return true
      }
      // 平仓的数量不能大于持仓数量
      if (quantity > CoinsQuantityTotal) {
        this.$toast(this.$t('features.closeText'))
        return true
      }
      return false
    },
    onShowKeyboard () {
      if (!this.showKeyboard) {
        this.showKeyboard = true
      }
    },
    getCoinsQuantity (side) {
      const futuresList = this.$refs.PositionDom?.futuresList || []
      return futuresList.filter(f => f.symbol === this.symbol && f.side === side).reduce((accumulator, currentValue) => accumulator.quantity + currentValue.quantity, { quantity: 0 }) || 0
    },
    ...mapActions(['updateTotalAssets'])
  }
}
</script>
<style lang="less">
.feature-index-handle-wrapper {
  background-color: #fff;
  box-sizing: border-box;
  overflow: scroll;
  height: calc(100% - 120px);
  padding-bottom: 100px;

  .van-cell {
    background-color: var(--background-color-1);
  }

  .feature-index-handle-operate {
    padding: 0 30px;
    width: 100%;

    .usdt {
      color: var(--main-text-2);
    }

    .active {
      color: var(--primary-color);
    }

    &-title {
      width: 100%;
      height: 56px;
      line-height: 56px;
    }

    &-button {
      position: relative;
      width: calc(50% - 21px);
      margin: 6px;
      text-align: center;
      font-family: Roboto;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      background-repeat: no-repeat;
      background-size: contain;

      span {
        position: absolute;
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &-withdraw {
      margin-top: 16px;
      width: 100%;
      color: var(--main-text-3);
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;

      img {
        margin-left: 8px;
        width: 32px;
        height: 32px;
      }
    }

    &-lever {
      margin: 16px 0 18px;
      width: 100%;
      height: 180px;
      border-radius: 8px;
      .m16 {
        margin: 0 16px;
      }
    }

    &-amount {
      padding: 20px 24px;
      height: 96px;
      color: var(--main-text-3);
      box-sizing: border-box;
      background: var(--background-color-2);
    }

    &-percentage {
      margin-top: 16px;
      height: 16px;
      .percentage-item {
        position: relative;
        height: 16px;
        background: var(--background-color-2);
        &::after {
          content: attr(data-value);
          position: absolute;
          top: 24px;
          left: 50%;
          transform: translateX(-50%);
          color: var(--main-text-3);
          font-family: Roboto;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          pointer-events: none;
        }
      }
      .text-active {
        &::after {
          color: var(--main-text-2);
        }
      }
      .percentage-item-active {
        background: var(--primary-color);
      }
    }

    &-symbol {
      margin-bottom: 24px;
      justify-content: space-between;
      flex-direction: column;
      height: 86px;
      color: var(--main-text-2);
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;

      .label {
        color: var(--main-text-3);
      }

      img {
        margin-left: 6px;
      }
    }

    &-operate {
      .button {
        width: 48%;
        height: 72px;
        color: #fff;
        text-align: center;
        font-family: Roboto;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;

        span {
          font-size: 20px;
          font-weight: 400;
        }
      }
    }
  }

  .btn {
    position: relative;
    cursor: pointer;
    user-select: none;
    height: 56px;
    .skew {
      text-indent: 10px;
    }
  }
  .btn.skew-l::after,
  .btn.skew-l::before {
    content: "";
    position: absolute;
    top: 0;
    background: var(--background-color-2);
    border-radius: 10px;
  }
  .btn.skew-l-a::after,.btn.skew-l-a::before {
    content: "";
    background: var(--primary-color);
  }
  .btn.skew-l::after {
    left: 0;
    right: 0;
    bottom: 0;
    transform: skewX(22deg);
  }

  .btn.skew-l::before {
    left: -13px;
    width: 100px;
    height: 56px;
  }
  // 右边
  .btn.skew-r::after,
  .btn.skew-r::before {
    content: "";
    position: absolute;
    top: 0;
    background: var(--background-color-2);
    border-radius: 10px;
  }
  .btn.skew-r-a::after,.btn.skew-r-a::before {
    content: "";
    background: var(--primary-color);
  }

  .btn.skew-r::after {
    left: 0;
    right: 0;
    bottom: 0;
    transform: skewX(22deg);
  }

  .btn.skew-r::before {
    right: -13px;
    width: 100px;
    height: 56px;
  }
}
</style>
