<template>
  <!-- futures/合约 -->
  <!-- <div>
    {{ $t('nav.futures') }}
    <FeatureIndex />
  </div> -->
  <FeatureIndex />
</template>
<script>
import FeatureIndex from '@/components/feature/index.vue'
export default {
  name: 'Futures',
  components: { FeatureIndex },
  computed: {
  },
  methods: {
  }
}
</script>
