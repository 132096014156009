<template>
  <div class="feature-index">
    <featureTicker @showMarket="onShowMarket" :price24="price24"/>
    <featureHandle :showMarket.sync="showMarket" :price24="price24"/>
  </div>
</template>

<script>
import featureTicker from '@/components/feature/ticker/index.vue'
import featureHandle from '@/components/feature/operate'

import { Ticker } from '@/api/market.js'

import { interval } from '@/utils/common.js'

export default {
  components: { featureTicker, featureHandle },
  data () {
    return {
      timer: null,
      showMarket: false,
      price24: {
        lastPrice: '',
        percent: ''
      }
    }
  },
  computed: {
    symbol () {
      return this.$route.params.symbol || 'BTCUSDT'
    }
  },
  watch: {
    '$route.path' (n, o) {
      if (n) {
        this.showMarket = false
      }
    }
  },
  created () {
    this.$toast.loading({
      mask: true,
      duration: 0 // 必须手动关闭
    })
    this.timer = interval(this.onPolling)
    this.timer.start()
  },
  beforeDestroy () {
    this.timer.stop()
  },
  destroyed () {
    this.timer.stop()
  },
  methods: {
    onShowMarket (bool) {
      this.showMarket = bool
    },
    onPolling () {
      return Ticker(this.symbol).then(res => {
        if (res) {
          this.price24.lastPrice = res.last_price
          this.price24.percent = res.price_change_percent
        }
        this.$toast.clear()
        return res
      })
    }
  }
}
</script>
<style lang="less">
.feature-index {
  height: 100%;
}
</style>
