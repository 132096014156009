<template>
  <div class="feature-index-handle-title ub ub-btw">
    <div class="chart ub ub-btw" @click="onShowLever">
      {{ lever }}X
    </div>
    <!-- <div class="feature-index-handle-title-funding ub">
      <span> {{ $t('wallets.funding') }} / {{ $t('trade.countdown') }} </span>
      <span class="rate-value">
        0.0026% / 00:03:00
      </span>
    </div> -->
    <van-popup
      v-model="showPop"
      round
      position="bottom"
      class="feature-index-handle-title-popup"
      get-container=".feature-index-handle-title"
    >
      <div class="title ub ub-btw">
        <span>
          {{ $t("features.adjustLever") }}
        </span>
        <img
          src="@/assets/images/system/cross.svg"
          alt="close"
          title="close"
          @click="showPop = false"
        />
      </div>
      <div class="lever ub ub-btw">
        <span @click="onMins">-</span>
        <p>{{ leverPop + "x" }}</p>
        <span @click="onPlus">+</span>
      </div>
      <Step :lever.sync="leverPop" />
      <van-button block :color="primaryColor" @click="onSubmit">{{ $t('confirm') }}</van-button>
    </van-popup>
  </div>
</template>
<script>
import Step from '@/components/feature/step'

export default {
  components: { Step },
  data () {
    return {
      showPop: false,
      lever: 5,
      leverPop: 5,
      minValue: 5,
      maxValue: 100,
      primaryColor: 'var(--primary-color)'
    }
  },
  methods: {
    onShowLever () {
      this.showPop = true
      this.leverPop = this.lever
    },
    onPlus () {
      if (this.leverPop !== this.maxValue) {
        this.leverPop++
      }
    },
    onMins () {
      if (this.leverPop !== this.minValue) {
        this.leverPop--
      }
    },
    onSubmit () {
      this.showPop = false
      this.lever = this.leverPop
    }
  }
}
</script>
<style lang="less">
.feature-index-handle-title {
  height: 100px;
  padding: 28px 30px;
  color: var(--main-text-3);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  padding: 0 32px;
  &-funding {
    flex-direction: column;
    color: var(--main-text-3);
    text-align: right;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    .rate-value {
      text-align: right;
      color: var(--main-text-1);
    }
  }
  &-popup {
    padding: 0 30px;
    width: 100%;
    height: 716px;
    background-color: var(--bg-1) !important;
    box-sizing: border-box;
    .title {
      margin: 48px 0;
      color: var(--main-text-1);
      font-family: Roboto;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      img {
        width: 48px;
        height: 48px;
      }
    }
    .lever {
      margin-bottom: 38px;
      width: 100%;
      height: 80px;
      border-radius: 8px;
      background: var(--background-color-2);
      color: var(--main-text-1);
      text-align: center;
      font-family: Roboto;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      span {
        margin: 0 24px;
        display: inline-block;
        width: 34px;
        color: var(--main-text-2);
      }
    }
  }
  img {
    transform: rotate(90deg);
    width: 32px;
    height: 32px;
    padding-bottom: 8px;
  }
  .chart {
    position: relative;
    color: var(--main-text-1);
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 150% */
    padding: 4px 16px;
    border-radius: 8px;
    background: var(--background-color-2);
    width: 68px;
    height: 36px;
    &::after {
      content: '';
      background: url('~@/assets/images/feature/array-little.svg') no-repeat;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      width: 32px;
      height: 32px;
    }
  }
}
</style>
